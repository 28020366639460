small.p-error {
    font-size: 13px;
    position: absolute;
    left: 42px;
    margin-top: 30px;
}
small.reg-error {
    font-size: 13px;
    position: absolute;
    color: red;
}
.hero_cover_one {
    position: relative;
     display: table;
     width: 100%;
     background-size: cover;
     background: url('../img/bg_banner.jpg');
     /*background:linear-gradient(to right, #f5e6d3, #fefcfd, #f8ebdc)!important;*/
     background-size: cover;
     background-repeat: no-repeat;
}
/* .view-image {
    margin-left: 95px;
} */
.view-image:hover {
color: green;
}

small.lending-error {
    position: absolute;
    color: red;
    top: 30px;
    left: 40px;
}
.landing-form-group{
    margin-bottom: 15px;
}
.landing-submit {
    text-align: center;
    margin-top: 30px;
}
.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #4b5563;
    padding: 0px;
}



